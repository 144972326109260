<template>
    <div class="scroll-div flex">
        <div class="table-operate" style="margin: 0 20px">
            <el-button type="text" icon="el-icon-document" @click="exportInfo()">导出数据</el-button>
        </div>

        <div class="flex-center">
            <template v-if="message">
                <ts-error></ts-error>
            </template>
            <template v-else>
                <div :id="nameKey" class="canvas small"></div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['comCode', 'visitId'],
        data() {
            return {
                nameKey: 'newDrugYear',
                message: false,

                list: [],
                cols: this.$help.generalCols([
                    {fieldName: 'year', name: '年份', width: 60, listClass: 'tc'},
                    {fieldName: 'amount', name: `销售额（${this.$variable.unit}）`, width: 100, listClass: 'tc'},
                    {fieldName: 'rate', name: '增长率(%)', width: 100, listClass: 'tc'},
                ]),
            }
        },
        mounted() {
            this.$api.get('drug/saleYear/sumYearAmountAndRateByComCode', {comCode: this.comCode}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.list = res.data.map(item => {
                            // item.year = this.$variable.yearMap[item.year]
                            item.rate = this.$help.parseFloat100(item.rate)
                            return item
                        })
                        this.$help.renderBar(this.nameKey, res.data, 'year')
                    } else {
                        this.message = res.code
                    }
                })
        },
        methods: {
            exportInfo(txt = '确定导出吗', query = {}) {
                this.$store.commit('confirm', {
                    content: txt,
                    callback:(next, back) => {
                        this.$api.get('drug/saleYear/exportSumYearAmountAndRateByComCode', this.$help.joinObj({comCode: this.comCode}, query))
                            .then(res => {
                                if (!(res.success || res.code === 'N0001')) {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === 'N0001') {
                                    next()
                                    this.exportInfo(res.message, {force: true})
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            },
        }
    }
</script>